import axios from 'axios';
const SMS_VERIFICATION_URL = '/widget/v1/patients/sms-verification';
const REGISTER_URL = '/widget/v1/patients/self-register';
const STATICS_URL = '/widget/v1/clinics/widget';
function getParams() {
    const params = new URLSearchParams(location.search);
    const token = params.get('clinic_token');
    const doctorId = params.get('doctor_id');
    return { token, doctorId };
}
const instance = axios.create({
    baseURL: __API__,
    timeout: 30000,
    headers: { 'Content-Type': 'application/json', 'X-Clinic-Token': getParams().token },
});
async function getWidgetConfig() {
    const { doctorId } = getParams();
    const response = await instance.get(STATICS_URL, { params: { doctor_id: doctorId } });
    return response.data;
}
async function smsVerification({ phone }) {
    const response = await instance.post(SMS_VERIFICATION_URL, null, {
        params: { 'verification[]': 'patient_registration', phone },
    });
    return response.data;
}
async function register({ phone, fullName, code, smsId, }) {
    const { doctorId } = getParams();
    await instance.post(REGISTER_URL, {
        phone,
        full_name: fullName,
        code,
        sms_id: smsId,
        doctor_id: Number(doctorId),
    });
}
export default {
    smsVerification,
    register,
    getWidgetConfig,
};
