<script lang="ts">
  import Button from '../common/Button/Button.svelte';
  import IbolitLogo from '../SmsConfirmation/assets/ibolit-logo.svg';
  import AppStoreIcon from './assets/app-store.svg';
  import GooglePlayIcon from './assets/google-play.svg';
  import { staticsStore } from '../../store';
  import useAssets from '../../utils/useAssets';

  export let isAndroid: boolean = false;
  export let isIOS: boolean = true;

  const { text } = useAssets();
  let statics = null;
  staticsStore.subscribe((value) => {
    statics = value;
  });

  const WEB_LINK = 'https://patient.ibolit.pro/auth';
  let IOS_LINK =
    'https://apps.apple.com/ru/app/ibolit-%D0%BF%D0%B0%D1%86%D0%B8%D0%B5%D0%BD%D1%82/id1345515799';
  let ANDROID_LINK = 'https://play.google.com/store/apps/details?id=pro.ibolit.iBolitPatient&hl=ru';

  if (statics) {
    if (statics.wl_url !== null) {
      IOS_LINK = statics.wl_url;
      ANDROID_LINK = statics.wl_url;
    }
  }

  $: isMobile = isAndroid || isIOS;
</script>

<div class="Success">
  <IbolitLogo class="Success__logo" fill="var(--pink)" width={148} height={58} />
  <h3>{text['success_title']}</h3>
  {#if !isMobile}
    <p>{text['success_is_pk']}</p>
  {:else}
    <p>{text['success_is_mobile']}</p>
  {/if}
  {#if isAndroid}
    <a href={ANDROID_LINK} target="_blank" rel="noopener noreferrer">
      <GooglePlayIcon class="Success__download-logo" width={147} height={42} />
    </a>
  {:else if isIOS}
    <a href={IOS_LINK} target="_blank" rel="noopener noreferrer">
      <AppStoreIcon class="Success__download-logo" width={147} height={42} />
    </a>
  {:else}
    <Button href={WEB_LINK}>{text['success_button_text']}</Button>
  {/if}

  <span class="Footer">{text['sms_confirmation_footer']} </span>
</div>

<style>
  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    text-align: center;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0;
    text-align: center;
  }

  .Success {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 23px;
  }

  .Footer {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #858585;
    padding-top: 36px;
  }

  :global(.Success__logo) {
    margin-bottom: 32px;
  }

  :global(.Success__download-logo) {
    margin-top: 40px;
  }
</style>
