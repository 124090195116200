<script>
  import Select from 'svelte-select';
  import Item from './CountryItem.svelte';
  import Selection from './CountrySelection.svelte';
  import Input from '../Input/Input.svelte';
  import { countries } from './countries.ts';
  import { isTgClinic } from '../../../constants/wl';

  export let phone = '';
  export let countryPhonePrefix = undefined;

  const items = countries.map((item) => {
    return {
      value: item.code,
      label: item.value,
      shortName: item.value,
      fullName: item.fullName,
      image_url: `images/countries/${item.value.toLowerCase()}.png`,
    };
  });

  const optionIdentifier = 'shortName';
  const labelIdentifier = 'label';
  // Set initial value based on requirements, ignore passed countryCode for now.
  let internalValue = items.find((item) => item.label === (isTgClinic ? 'TJ' : 'RU'));

  const handleSelect = (event) => {
    internalValue = event.detail;
    // Actual value
    countryPhonePrefix = event.detail.value;
  };
</script>

<div class="countryCodeWrapper">
  <div class="select_wrapper">
    <Select
      {labelIdentifier}
      {optionIdentifier}
      value={internalValue}
      isClearable={false}
      listAutoWidth={true}
      isSearchable={false}
      showIndicator={false}
      indicatorSvg={'<svg width="0" height="0"/>'}
      on:select={handleSelect}
      {items}
      {Item}
      {Selection}
    />
  </div>
  <Input isPhoneInput={true} type="tel" mask bind:value={phone} />
</div>

<style>
  .countryCodeWrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  .select_wrapper {
    /* Override some select variables */
    --height: 46px;
    --selectedItemPadding: 0;
    --borderRadius: 8px;
    --inputPadding: 0;
    --padding: 0;
    --border: none;
    --listRight: none;
    /* QUALITY STYLING*/
    height: 48px;
    display: flex;
    align-items: center;
    background: var(--silver);
    padding-left: 1px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  :global(.Input.Phone) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  :global(.selectContainer) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  :global(.selectedItem) {
    display: flex;
    align-items: center;
  }
</style>
