<script lang="ts">
  import { fade } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  import api from '../../api';
  import { staticsStore } from '../../store';
  import Input from '../common/Input/Input.svelte';
  import Button from '../common/Button/Button.svelte';
  import IntroBackground from '../Background/IntroBackground.svelte';
  import SmsConfirmation from '../SmsConfirmation/SmsConfirmation.svelte';
  import CountryCodeSelect from '../common/CountryCodeSelect/CountryCodeSelect.svelte';
  import useAssets from '../../utils/useAssets';
  import { isTgClinic } from '../../constants/wl';

  enum FormStep {
    Initial,
    Confirmation,
  }

  const dispatch = createEventDispatcher();
  const { text } = useAssets();

  let step = FormStep.Initial;
  let name = '';
  let phone = '';
  let countryPhonePrefix = isTgClinic ? '+992' : '+7';
  let verificationId = '';
  let isFetching = false;
  let statics = null;
  export let hasIntroAnimationBeenRun;

  staticsStore.subscribe((value) => {
    statics = value;
  });

  function getParsedNumber(number: string) {
    return number.replace(/[+()\- ]/g, '');
  }

  function getFullPhoneNumber() {
    return countryPhonePrefix + phone.replace(/[+()\- ]/g, '');
  }

  async function handleRegisterClick() {
    isFetching = true;

    try {
      const { id } = await api.smsVerification({ phone: getFullPhoneNumber() });
      isFetching = false;

      step = FormStep.Confirmation;
      verificationId = id;
    } catch (error) {
      isFetching = false;

      if (error.response && error.response.status === 429) {
        const errorString = text['form_limit_requests'];
        dispatch('failure', { error: errorString });
        return;
      }

      dispatch('failure');
    }
  }

  function handleBackPress() {
    step = FormStep.Initial;
  }

  $: animationConfig = hasIntroAnimationBeenRun
    ? { HEADER_FADE_IN_DURATION: 0, FLY_IN_DURATION: 0, FORM_FADE_IN_DURATION: 0 }
    : { HEADER_FADE_IN_DURATION: 600, FLY_IN_DURATION: 1000, FORM_FADE_IN_DURATION: 300 };
  $: isButtonDisabled = !name || getParsedNumber(phone).length === 0 || isFetching;
  $: isReady = statics !== null;
</script>

<div class="Form__container" class:Form__container--confirm={step === FormStep.Confirmation}>
  {#if isReady}
    {#if step === FormStep.Initial}
      <IntroBackground {...animationConfig} />
      <div
        in:fade={{ delay: animationConfig.HEADER_FADE_IN_DURATION + 3 * animationConfig.FLY_IN_DURATION, duration: animationConfig.FORM_FADE_IN_DURATION }}
        on:introend={() => dispatch('animationBeenRun')}
        class="Form"
      >
        <label>{text['form_fio']}</label>
        <Input placeholder={text['form_phone_placeholder']} bind:value={name} />
        <label>{text['form_phone_title']}</label>
        <CountryCodeSelect bind:phone bind:countryPhonePrefix />
        <Button class="Form__button" disabled={isButtonDisabled} on:click={handleRegisterClick}>
          {text['form_button_submit']}
        </Button>
        <div class="Form__terms">{text['form_terms']}</div>
      </div>
    {:else if step === FormStep.Confirmation}
      <SmsConfirmation
        phone={getFullPhoneNumber()}
        {name}
        {verificationId}
        on:back={handleBackPress}
        on:failure
        on:success
      />
    {/if}
  {/if}
</div>

<style>
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
  }

  label {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }

  .Form__container {
    background-color: var(--silver);
    border-radius: inherit;
    height: 100%;
  }

  .Form__container--confirm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #fff;
  }

  .Form {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px;
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
  }

  .Form__terms {
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    width: 80%;
    color: var(--darkGrey);
  }

  .Form__terms a {
    color: var(--pink);
    text-decoration: none;
  }
</style>
