<script>
  import Chevron from './chevron.svg';
  export let item = undefined;
  export let getSelectionLabel = undefined;
</script>

<div class="customSelection">
  <Chevron />
  <img src={item.image_url} alt={item.code} />
  <div class="customSelection_title">{item.value}</div>
</div>

<style>
  .customSelection {
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  img {
    margin: 0 8px;
    width: 24px;
  }

  .customSelection_title {
    color: var(--black);
    flex: 1;
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 40px;
  }
</style>
