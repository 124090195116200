<script>
  export let item = undefined;
  export let isActive = false;
  export let isFirst = false;
  export let isHover = false;
  export let isSelectable = true;
  export let filterText = '';
  export let getOptionLabel = '';

  let itemClasses = '';

  $: {
    const classes = [];

    if (isActive) {
      classes.push('active');
    }
    if (isFirst) {
      classes.push('first');
    }
    if (isHover) {
      classes.push('hover');
    }
    itemClasses = classes.join(' ');
  }
</script>

<div class={`customItem ${itemClasses}`}>
  <img src={item.image_url} alt={item.name} />
  <div class="customItem_countryName">{item.fullName}</div>
  <div class="customItem_title">{item.value}</div>
</div>

<style>
  .customItem {
    min-width: 300px;
    display: flex;
    align-items: center;
    cursor: default;
    height: 40px;
    line-height: 40px;
    padding-left: 16px;
    padding-right: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid var(--silver);
  }

  img {
    width: 24px;
    margin-right: 8px;
  }

  .customItem_countryName {
    margin-right: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .customItem:active {
    background: #b9daff;
  }

  .customItem.active {
    background: var(--pinkOpacity);
    color: var(--black);
  }

  .customItem.first {
    border-radius: 4px 4px 0 0;
  }

  .customItem.hover:not(.active) {
    background: #e7f2ff;
  }

  .customItem_title {
    flex: 1;
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
