<script lang="ts">
  import Form from './components/Form/Form.svelte';
  import Success from './components/Success/Success.svelte';
  import Failure from './components/Failure/Failure.svelte';
  import { staticsStore } from './store';
  import { onMount } from 'svelte';
  import api from './api';
  import useAssets from './utils/useAssets';

  enum AppStep {
    Form,
    Success,
    Failure,
  }

  const { text } = useAssets();

  const isAndroid = !!navigator.userAgent.match(/Android/i);
  const isIOS =
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  let step = AppStep.Form;
  let error = '';
  let isSetupError = false;
  // We want the cool intro animation to run only once.
  let hasIntroAnimationBeenRun = false;

  // Grab the config of widget
  onMount(async () => {
    try {
      const widgetConfig = await api.getWidgetConfig();
      staticsStore.set(widgetConfig);
    } catch (error) {
      isSetupError = true;
    }
  });

  function handleAnimationFinish() {
    hasIntroAnimationBeenRun = true;
  }

  function handleStepChange(nextStep: AppStep) {
    return () => {
      error = '';
      step = nextStep;
    };
  }

  function handleSuccess() {
    handleStepChange(AppStep.Success)();
  }

  function handleError({ detail }: { detail: { error: string } | null }) {
    handleStepChange(AppStep.Failure)();

    if (detail) {
      error = detail.error;
    }
  }
</script>

<svelte:head>
  <title>{text.title}</title>
</svelte:head>

<main>
  {#if isSetupError === true}
    <Failure isSetupError={true} />
  {:else if step === AppStep.Form}
    <Form
      {hasIntroAnimationBeenRun}
      on:animationBeenRun={handleAnimationFinish}
      on:success={handleSuccess}
      on:failure={handleError}
    />
  {:else if step === AppStep.Success}
    <Success {isAndroid} {isIOS} />
  {:else if step === AppStep.Failure}
    <Failure {error} on:click={handleStepChange(AppStep.Form)} />
  {/if}
</main>

<style>
  main {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 500px) {
    main {
      height: 100%;
      width: 100%;
    }
  }

  @media (min-width: 500px) {
    main {
      border-radius: 16px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08), 0 1px 4px rgba(0, 0, 0, 0.08);
      max-width: 400px;
      width: 100%;
    }
  }

  :global(:root) {
    --black: #000;
    --blue: #00b5ce;
    --danger: #e20b22;
    --darkGrey: #858585;
    --green: #35cf4e;
    --lightBlack: #212121;
    --lightBlue: #f3f9fa;
    --pink: #ff537c;
    --silver: #f5f5f5;
    --pinkOpacity: #ff537c4d;
  }
</style>
