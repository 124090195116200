const defaultAssets = {
    text: {
        title: 'Регистрация',
        'info_background_bubble-first': 'Здравствуйте, теперь вы можете задать мне все вопросы онлайн!',
        'info_background_bubble-wide': 'Для этого зарегистрируйтесь и скачайте приложение.',
        'info_background_bubble-narrow': 'До встречи в приложении!',
        form_fio: 'Укажите ваше ФИО',
        form_phone_title: 'Введите номер телефона',
        form_phone_placeholder: 'Имя',
        form_button_submit: 'Подтвердить номер',
        form_terms: 'Регистрируясь, вы даете согласие на обработку ваших персональных данных',
        form_limit_requests: 'Аз ҳад зиёд дархостҳо',
        sms_confirmation_title: 'Подтвердите номер',
        sms_confirmation_subtitle: ' Для продолжения необходимо ввести код из СМС, отправленного на номер',
        sms_confirmation_enter_code: 'Введите код из СМС',
        sms_confirmation_use_clinic: 'Наша клиника использует приложение для телемедицины',
        sms_confirmation_button_accept: 'Подтвердить',
        sms_confirmation_footer: 'Если у вас возникли трудности со входом в приложение — свяжитесь с',
        code_input_error_code: 'Неверный код',
        code_input_repeat_code: 'Повторно код можно отправить через:',
        code_input_repeat_code2: 'сек',
        code_input_button_repeat: 'Отправить еще раз',
        success_title: 'Вы успешно зарегистрировались',
        success_is_pk: 'Авторизуйтесь в личном кабинете iBolit',
        success_is_mobile: 'На указанные данные придет сообщение со ссылкой на скачивание приложения',
        success_button_text: 'Авторизоваться',
        failure_title: 'Что-то пошло не так',
        failure_repeat_after: 'Попробуйте повторить попытку',
        failure_repeat_after_button: 'Повторить еще раз',
        failure_repeat_setup_error1: 'Сломались настройки виджета',
        failure_repeat_setup_error2: 'Обратитесь в службу поддержки',
    },
};
const tgAssets = {
    text: {
        title: 'Бақайдгирӣ',
        'info_background_bubble-first': 'Салом, акнун шумо метавонед аз ман машварати онлайн гиред!',
        'info_background_bubble-wide': 'Барои ин, худро қайд намоед ва барномаро насб кунед',
        'info_background_bubble-narrow': 'То дидор дар барнома!',
        form_fio: 'Ном, насаб, номи падар',
        form_phone_title: 'Рақами телефон',
        form_phone_placeholder: 'Ном',
        form_button_submit: 'Тасдиқ кардан',
        form_terms: 'Бо сабти ном, шумо розигӣ медиҳед барои коркарди маълумоти шахсӣ',
        form_limit_requests: 'Слишком много запросов',
        sms_confirmation_title: 'Шумораро тасдиқ кунед',
        sms_confirmation_subtitle: 'Барои идома додан ворид кардани рамз аз паёме, ки фиристода шуд, ҳатмист',
        sms_confirmation_enter_code: 'Рамзро аз паём ворид кунед',
        sms_confirmation_use_clinic: 'Дармонгоҳи мо барномаро барои телетиб истифода мебарад',
        sms_confirmation_button_accept: 'Тасдиқ кардан',
        sms_confirmation_footer: 'Ҳангоми ворид шудан ба барнома мушкили пеш омад? Ба мо тамос гиред. +992003003001',
        code_input_error_code: 'Рамз нодуруст',
        code_input_repeat_code: 'Шумо метавонед кодро тавассути:',
        code_input_repeat_code2: 'сек',
        code_input_button_repeat: 'Бори дигар фиристодан',
        success_title: 'Шумо аз қайд гузаштед. Паёмакро интизор шавед',
        success_is_pk: 'Ба раками гузоштаи Шумо паём бо пайванд равон карда шуд. Пайванди дар паём омадаро пайгири намоед, ё мустақилона барномаи мобилии  «Docmed.online »-ро ба телефонатон насб кунед',
        success_is_mobile: 'Ба раками гузоштаи Шумо паём бо пайванд равон карда шуд. Пайванди дар паём омадаро пайгири намоед, ё мустақилона барномаи мобилии  «Docmed.online »-ро ба телефонатон насб кунед',
        success_button_text: 'Даромадан',
        failure_title: 'Мушкиле пеш омад',
        failure_repeat_after: 'Лутфан бори дигар кӯшиш кунед',
        failure_repeat_after_button: 'Дертар такроран санҷед',
        failure_repeat_setup_error1: 'Танзимоти виджет вайрон шудааст',
        failure_repeat_setup_error2: 'Бо дастгирии тамос',
    },
};
export default {
    defaultAssets,
    tgAssets,
};
