<script lang="ts">
  import { MaskedInput } from 'svelte-imask';

  export let placeholder: string = '';
  export let value: string = '';
  export let disabled: boolean = false;
  export let mask: string | undefined = undefined;
  export let isPhoneInput = false;
  let className: string = '';
  export { className as class };

  function handleInput({ detail }) {
    const inputValue = detail.value;
    value = inputValue;
  }
</script>

{#if mask}
  <MaskedInput
    class={`Input${isPhoneInput ? ' Phone' : ''}`}
    {disabled}
    {placeholder}
    {value}
    options={{ mask: /^\d+$/ }}
    on:accept={handleInput}
    {...$$restProps}
  />
{:else}
  <input class="Input {className}" {disabled} {placeholder} bind:value on:input {...$$restProps} />
{/if}

<style>
  /*
   Scoped styles don't work when passed to another component
   https://github.com/sveltejs/svelte/issues/2870
  */
  :global(.Input) {
    background-color: var(--silver);
    border-radius: 8px;
    border: 0;
    color: var(--black);
    font-size: 16px;
    line-height: 24px;
    margin: 8px 0;
    outline: none;
    padding: 12px 16px;
    width: 100%;
  }

  .Input::placeholder,
  :global(.Input::placeholder) {
    color: #c7c7c7;
  }
</style>
